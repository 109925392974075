.button-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 25px;
  gap: 1rem;
}

.button-wrapper button:first-child {
  background-color: #294992;
  width: 8rem;
  border-radius: 20px;
  padding: 9px 10px;
  display: flex;
  font-size: 0.9rem;
  color: white;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  display: flex;
  gap: 0.6rem;
}

.button-wrapper button:last-child {
  background-color: white;
  width: 8rem;
  border-radius: 20px;
  padding: 9px 10px;
  font-size: 0.9rem;
  color: #294992;
  font-weight: 600;
}

.order-history-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  gap: 1.9rem;
}
.order-history-wrapper button {
  background-color: white;
  width: max-content;
  border-radius: 24px;
  padding: 11px 25px;
  display: flex;
  font-size: 1rem;
  color: #294992;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.button-wrapper-mobile {
  display: none;
}
.progress_text {
  width: max-content;
}

.grid-container {
  margin-top: 1rem;
  width: 100%;
}

.grid-item {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 0.45fr 0.056fr 0.6fr;
  margin-top: 0.1rem;
}
.addtest-btn {
  background-color: #2a4795;
  height: 1.6rem;
  border-radius: 20px;
  padding: 0.12rem 0.5rem;
  color: white;
  font-size: 0.8rem;
}
.label {
  color: #415e9e;
  font-size: 11pt;
}
.colon {
  color: #415e9e;
  font-size: 11pt;
}
.value {
  font-size: 11pt;
  margin-top: 0.12rem;
}

.abc-logo {
  object-fit: cover;
  height: 1.4cm;
  width: 3.7cm;
  margin-top: 0.5rem;
}

@media screen and (max-width: 1535px) {
  .button-wrapper {
    display: none;
  }
  .button-wrapper-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    gap: 1.9rem;
    position: fixed;
    bottom: 0;
    background: #7393c2;
    width: 100%;
    left: 0;
    transform: translateY(-44px);
    padding: 0.5rem 0;
  }
  .button-wrapper-mobile button:first-child {
    background-color: #294992;
    width: 8rem;
    border-radius: 20px;
    padding: 9px 10px;
    display: flex;
    font-size: 0.9rem;
    color: white;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    display: flex;
    gap: 0.6rem;
  }

  .button-wrapper-mobile button:last-child {
    background-color: white;
    width: 8rem;
    border-radius: 20px;
    padding: 9px 10px;
    font-size: 0.9rem;
    color: #294992;
    font-weight: 600;
  }
}
