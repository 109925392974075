.submitBtn {
  background-color: #1677ff;
  background-image: none;
}
:where(.css-dev-only-do-not-override-1e3x2xa).ant-switch {
  background: rgba(0, 0, 0, 0.45);
}
:where(.css-1e3x2xa).ant-switch {
  background: rgba(0, 0, 0, 0.45);
}
.space-align-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.space-align-block {
  flex: none;
  margin: 8px 4px;
  padding: 4px;
}
.space-align-block .mock-block {
  display: inline-block;
  padding: 25px 8px 16px;
}

:where(.css-dev-only-do-not-override-1e3x2xa).ant-modal .ant-modal-footer {
  text-align: start;
}
:where(.css-1e3x2xa).ant-modal .ant-modal-footer {
  text-align: start;
}
