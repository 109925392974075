@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;500&display=swap");

* {
  scroll-behavior: smooth;
  font-family: "Karla", sans-serif;
  font-weight: 500;
}
.App {
  text-align: center;
}

.required::after {
  content: " *";
  color: red;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#root
  > div.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.css-hyum1k-MuiToolbar-root {
  display: none;
}

.dynamic-test-modal {
  border-radius: 2rem !important;
}
.ant-btn.ant-btn-primary.ant-btn-sm {
  background: #1677ff !important;
}
.ant-btn.ant-btn-primary.ant-btn-sm:disabled {
  background-color: #f5f5f5 !important;
}

.ant-btn.ant-btn-primary.danger {
  background: #ff4d4f !important;
}
.ant-btn.ant-btn-primary.danger:hover {
  background-color: #ff686b !important;
}

.popconfirm-btn {
  background: #1677ff !important;
  color: white !important;
}

::-ms-reveal {
  display: none;
}
/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-switch {
  background-color: gray !important;
}
.ant-switch.ant-switch-checked {
  background-color: #1677ff !important;
}

.ant-table-thead .ant-table-cell {
  font-weight: bold !important;
  font-size: 0.875rem !important;
  height: 50px !important;
}

.order-status-tag {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='black' stroke-width='4' stroke-dasharray='20%2c 20' stroke-dashoffset='16' stroke-linecap='square'/%3e%3c/svg%3e");
}
/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* .MuiListItemIcon-root.css-cveggr-MuiListItemIcon-root {
  min-width: 41px !important;
}

.MuiListItemIcon-root.css-c6a9cx-MuiListItemIcon-root {
  min-width: 50px !important;
} */

@media print {
  .test-order-wrapper {
    margin: 1.27cm;
  }
  .session-summary-wrapper {
    margin: 1.27cm;
  }
  .session-summary-header {
    width: 100%;
    padding-bottom: 0.6rem;
    border-bottom: 2px solid black;
    page-break-after: auto;
    margin-top: 1rem;
  }
}
