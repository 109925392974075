.submit-btn {
  background-color: #294992;
  width: max-content;
  border-radius: 20px;
  padding: 11px 27px;
  display: flex;
  font-size: 0.8rem;
  color: white;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.submit-btn:disabled {
  background-color: #5e73a5;
  color: whitesmoke;
  cursor: not-allowed;
}
