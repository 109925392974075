.save_btn:disabled {
  background-color: #7393c2;
  height: 2.5rem;
  border-radius: 20px;
  padding: 0.3rem 2.5rem;
  color: white;
  font-size: 1rem;
}
.save_btn {
  background-color: #2a4795;
  height: 2.5rem;
  border-radius: 20px;
  padding: 0.3rem 2.5rem;
  color: white;
  font-size: 1rem;
}
.selected-row {
  background: #dfecff !important;
}

.cancel_btn {
  background-color: #2a4795;
  height: 2.5rem;
  border-radius: 20px;
  padding: 0.3rem 2.5rem;
  color: white;
  font-size: 1rem;
}

.pStyle {
  color: #2a4993;
  font-size: 1rem;
  margin-left: 0.8rem;
}

.glukosapStyle {
  color: #2a4993;
  font-size: 1rem;
  margin-left: 0.8rem;
  margin-bottom: 1rem;
}
.spanStyle {
  color: black !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
}
