.progress-bar {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  color: white;
  font-weight: 600;
}
.progress-bar .step {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.7rem;
}
.progress-bar .step p {
  font-weight: 300;
  font-size: 16px;
  color: #000;
  color: white;
}
.progress-bar .step .bullet {
  height: 27px;
  width: 27px;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
}

.progress-bar .step .bullet span {
  font-weight: 600;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.progress-bar .step .bullet:before,
.progress-bar .step .bullet:after {
  position: absolute;
  content: "";
  bottom: -23px;
  left: 12.5px;
  height: 23px;
  width: 2.39px;
  background: #ffffff;
}

.progress-bar .step:last-child .bullet:before,
.progress-bar .step:last-child .bullet:after {
  display: none;
}

.button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  gap: 1.9rem;
}

.button-wrapper button:first-child {
  background-color: white;
  width: 6.5rem;
  border-radius: 20px;
  padding: 9px 18px;
  display: flex;
  font-size: 0.8rem;
  color: #294992;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.button-wrapper button:last-child {
  background-color: white;
  width: 6.375rem;
  border-radius: 20px;
  padding: 9px 20px;
  display: flex;
  font-size: 0.8rem;
  color: #294992;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.order-history-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  gap: 1.9rem;
}
.order-history-wrapper button {
  background-color: white;
  width: max-content;
  border-radius: 24px;
  padding: 11px 25px;
  display: flex;
  font-size: 1rem;
  color: #294992;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.divider {
  height: 1.9rem;
  width: 1.3px;
  background-color: #294992;
}
.button-wrapper-mobile {
  display: none;
}
.progress_text {
  width: max-content;
}
@media screen and (max-width: 1535px) {
  .progress-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    flex-direction: row;
    gap: 1.5rem;
    margin-top: 2rem;
  }
  .progress-bar .step {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  .progress-bar .step .bullet {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    position: relative;
    font-weight: bold;
    font-size: 17px;
    line-height: 40px;
  }
  .progress-bar .step .bullet span {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .progress-bar .step .bullet:before,
  .progress-bar .step .bullet:after {
    position: absolute;
    content: "";
    bottom: 20px;
    left: 41px;
    height: 2.5px;
    width: 46px;
    background: #ffffff;
  }

  .progress-bar .step:last-child .bullet:before,
  .progress-bar .step:last-child .bullet:after {
    display: none;
  }

  .progress-bar .step p {
    display: none;
  }
  .button-wrapper {
    display: none;
  }
  .button-wrapper-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    gap: 1.9rem;
    position: fixed;
    bottom: 0;
    background: #7393c2;
    width: 100%;
    left: 0;
    transform: translateY(-44px);
  }
  .button-wrapper-mobile button {
    background-color: white;
    width: 6.375rem;
    border-radius: 20px;
    padding: 9px 20px;
    display: flex;
    font-size: 0.8rem;
    color: #294992;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    margin: 0.3rem 0.2rem;
  }
}
