:where(.css-dev-only-do-not-override-1e3x2xa).ant-collapse-small
  > .ant-collapse-item
  > .ant-collapse-header {
  padding: 0px;
}
:where(.css-1e3x2xa).ant-collapse-small
  > .ant-collapse-item
  > .ant-collapse-header {
  padding: 0px;
}

:where(.css-dev-only-do-not-override-1e3x2xa).ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 0px;
  color: rgba(0, 0, 0, 0.88);
  line-height: 1.5714285714285714;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
}
:where(.css-1e3x2xa).ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 0px;
  color: rgba(0, 0, 0, 0.88);
  line-height: 1.5714285714285714;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
}
