.divider {
  height: 0.05rem;
  background-color: #203977;
  width: 100%;
  border-radius: 0.3rem;
}

.grid-container {
  width: 100%;
}

.grid-item {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 0.8fr 0.1fr 1fr;
  margin-top: 0.1rem;
}
.addtest-btn {
  background-color: #2a4795;
  height: 1.6rem;
  border-radius: 20px;
  padding: 0.12rem 0.5rem;
  color: white;
  font-size: 0.8rem;
}
.label {
  color: #415e9e;
  font-size: 11pt;
}
.colon {
  color: #415e9e;
}
.value {
  font-size: 11pt;
  margin-top: 0.2rem;
}
.diagnosis-list {
  list-style: none;
  margin-left: 1.2rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}
.handling-list {
  list-style: none;
  margin-left: 1.2rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.additional-info-text {
  color: #2a4993;
}
.test-type {
  color: black;
  font-weight: 600;
}
