.ml-auto {
  margin-left: auto;
}
.MuiOutlinedInput-notchedOutline {
  border: 2px solid red !important;
}

.form-input-label {
  color: #294991;
  font-weight: 600;
  padding: 0.4rem 0;
  margin-top: 0.5rem;
}
.form-input {
  width: 100%;
  position: relative;
  outline: none !important;
  border-radius: 5px;
  background-color: white;
}
.form-date-picker {
  border-radius: 5px;
}

.address-title p {
  color: #294991;
}
.address-title span {
  color: #294991;
  font-weight: 600;
}
.footer-btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-top: 2.5rem;
}
.request-header-text {
  color: #294991;
}
.alert-message {
  color: red;
}

.email-suggestion-box {
  width: 100%;
  position: absolute;
  top: 4rem;
  left: 0;
  z-index: 2;
  box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.5);
  height: auto;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #c1dbff;
}

.email-suggestion-box p {
  cursor: pointer;
  padding: 0.3rem 0.9rem;
}
.email-suggestion-box p:hover {
  background-color: #a5cbff;
}
.emails-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  width: auto;
  overflow-x: scroll;
}

.email-input-wrapper {
  border: 2px solid #6e93ca !important;
  width: 100%;
  outline: none !important;
  border-radius: 5px;
  background-color: white;
  height: 2.75rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0.3rem 0.8rem;
  cursor: text;
}

.email-input-wrapper ul {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.email-input-wrapper ul li {
  width: auto;
  padding: 0rem 0.3rem;
  font-size: 1rem;
}

.email-input-wrapper:focus-within {
  border: 2px solid #1976d2 !important;
}

.email-input-wrapper input {
  appearance: none;
  border: none;
  outline: none;
  font-size: 1rem;
  width: 100%;
}

.error-message {
  color: red;
  font-size: 0.8rem !important;
}
